import React from 'react'
import Global from '../../Global'

function Grafico({item}) {

    let urlFoto = Global.BASE_URL + 'api/archivos/image/' + item.imagen;

    if (item.tieneImagen === true) {
        return(
            <img src={urlFoto} alt="foto" />
        )
    } else {
        return null;
    }

}

export default Grafico