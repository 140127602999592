import React from 'react'
import { useState, useEffect, useContext } from 'react'
import ErrorUrl from './ErrorUrl'
import Cargando from './Cargando'
import Global from '../Global'
import Navegar from './Navegar'
import Pie from './Pie'

import UsuarioContext from '../UsuarioContext'


function EstudianteEvaluaciones() {

    const [ estadoUsuario ] = useContext(UsuarioContext);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [instrumentos, setInstrumentos] = useState([]);

    useEffect(() => {

        let url = Global.BASE_URL + 'api/estudianteevaluaciones';

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${estadoUsuario.token}`);

        var miInit = { 
            method: 'GET', 
            headers: myHeaders,
        };

        fetch(url, miInit)
            .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setInstrumentos(result);
                    },
                    (error) => {
                        setIsLoaded(false);
                        setError(error);
                    }
            ) 
    }, [])   

    if (error) {
        return (
            <ErrorUrl />
        ) 
    } else if (!isLoaded) {
        return (
            <Cargando />
        ) 
    } else {

        if (instrumentos.length > 0) {
            return(
                <React.Fragment>
                    <Navegar />
                    <div className="container mt-1 mb-5">
                        <h4>Mis evaluaciones</h4>
                    
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Evaluacion</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Puntaje</th>
                                </tr>
                            </thead>
                            <tbody>
                                {instrumentos.map(i => (
                                    <tr key={i.estudianteInstrumentoId}>
                                        <th scope="row">{i.instrumento}</th>
                                        <td>{i.fechaAplicacion}</td>
                                        <td>{i.puntaje}</td>
                                    </tr>                   
                                ))}
                            </tbody>
                        </table>
                    </div>                
                    <Pie />
                </React.Fragment>
            )
    
        } else {
            return(
                <React.Fragment>
                    <Navegar />
                        <div className="container mt-5 mb-5 pt-5">
                            <h4>Mis evaluaciones</h4>
                            <p>Todavia no tiene evaluaciones procesadas...</p>
                            <p>Si realizó algún examen, espere que el sistema procese los resultados para poder verlos aquí.</p>
                        </div>
                    <Pie />
                </React.Fragment>
                )
        }
    }
}

export default EstudianteEvaluaciones