import React from 'react'
import { useContext } from 'react'
import UsuarioContext from './UsuarioContext'
import { Route, Redirect  } from 'react-router-dom'; 

function PrivateRoute ({ component: Component, ...rest }) {

    const [context ] = useContext(UsuarioContext)

    return(
        <Route 
            {...rest}
            render={
                props => {
                    if (context.exito === true) 
                    {
                        return <Component {...props} />;
                    } 
                    else 
                    {
                        return <Redirect to='/login' />
                    }
                }
            }
        />
    )
}

  export default PrivateRoute