import React from 'react'
import { useState, useContext, useEffect } from 'react'
import Global from '../Global'
import UsuarioContext from '../UsuarioContext'
import notify from 'devextreme/ui/notify';
import Navegar from './Navegar'
import Pie from './Pie'

function CambiarClave() {

    const [ estadoUsuario, setEstadoUsuario ] = useContext(UsuarioContext)

    const [exito, setExito] = useState()

    const [datos, setDatos] = useState({
        clave1: '',
        clave2: '',
    });

    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name] : event.target.value
        })
    };

    useEffect(() => {
        if (exito != null) {

            let mensaje = "La contraseña se cambió con exito"

            var tipo = (exito === true) ? 'success' : 'error'

            if (exito !== true) {
                mensaje = "No se pudo cambiar la contraseña"
            }

            notify({
                message: mensaje,
                position: {
                    my: 'center top',
                    at: 'center top'
                }
            }, tipo, 3000);
        }
    }, [exito])


    const onFormSubmit = (e) => {

        if (datos.clave1 !== datos.clave2) {

            notify({
                message: "Las constraseñas no coinciden",
                position: {
                    my: 'center top',
                    at: 'center top'
                }
            }, 'error', 3000);

        } else {

            let url = Global.BASE_URL + 'api/cambiarclave';

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${estadoUsuario.token}`);

            var miInit = { 
                method: 'POST', 
                body: JSON.stringify(datos), 
                headers: myHeaders,
            };

            fetch(url, miInit)
                .then(respuesta => {
                    if (respuesta.ok) {
                        return respuesta;
                    } 
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        setExito(result);
                    },
                    (error) => {
                        setExito(false);                    
                    }
                );
        }
    
        e.preventDefault();

    };

    return(              
        <React.Fragment>
        
            <Navegar />

            <div className="card-body">
                <h5 className="card-title text-center text-primary">Cambiar contraseña</h5>
                <form onSubmit={onFormSubmit}>
                    <div className="form-row mt-4 d-flex justify-content-center">
                        <div className="col-12 col-sm-10 col-md-7">
                            <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Clave</div>
                                </div>
                                <input 
                                type="password" 
                                className="form-control" 
                                id="clave1" 
                                name="clave1"
                                autoComplete="new-password"
                                placeholder="Contraseña"
                                value={datos.password}
                                onChange={handleInputChange}
                                />
                            </div>

                            <div className="input-group my-4 mr-sm-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Repetir clave</div>
                                </div>
                                <input 
                                    type="password" 
                                    className="form-control" 
                                    id="clave2" 
                                    name="clave2"
                                    autoComplete="new-password"
                                    placeholder="Repetir clave"
                                    value={datos.password}
                                    onChange={handleInputChange}
                                />
                            </div>
                            
                            <div className="w-100 d-flex justify-content-center">
                                <input type="submit" className="btn btn-primary rounded-pill px-4" value="Cambiar contraseña"/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Pie />
        </React.Fragment>
    )
}

export default CambiarClave