import React from 'react'
import Global from '../Global'

function PublicacionRecurso({recurso}) {

    let url = Global.BASE_URL + 'api/archivos/general/' + recurso.archivo

    return(
        <div key={recurso.publicacionRecursoId} className="my-2">
            <span className="ml-2"><img src="/pdf-icono.png" width="22px" alt="pdf" /></span>
            <a href={url}> {recurso.titulo} </a>
        </div>
    )
}

export default PublicacionRecurso
