import React from 'react'
import VacantesCore from './VacantesCore'
import FormularioInscripcion from '../Inscripcion/FormularioInscripcion'
import Calendario from '../Calendario'
import Costos from '../Costos'
import Navegacion from '../Navegacion'
import Pie from '../Pie'

function Vacantes() {
    return (
        <div className="w-100 fondo-body">
            <Navegacion />
            <VacantesCore />
            <FormularioInscripcion />
            <Calendario />
            <Costos />
            <Pie />
        </div>
    )
}

export default Vacantes