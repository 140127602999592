import React from 'react'
import VentajasCore from './VentajasCore'

function Ventajas() {

    return(
        <div className="py-5 px-2">
            <div className="container bg-white rounded-lg py-4 shadow border">
                <h3 className="text-center">VENTAJAS DE ESTUDIAR EN LA CEPRE</h3>
                <VentajasCore />
            </div>
        </div>
    )
}

export default Ventajas