import React, { useEffect, useState } from 'react'

function Semana({semanaActual, semanas, handleChange}) {

    const [opciones, setOpciones] = useState()

    
    const getOpciones = (limite) => {
        let items = [];
        for (var i = 1; i <= limite; i++) {
            items.push(<option key={i} value={i} >Semana {i}</option>);
        } 
        return items;
    }

    useEffect(() => {
        setOpciones(getOpciones(semanas));
    }, [semanas])

    return(

        <div className="mt-4">
            <div className="form-group row">
                <label htmlFor="seleccionar-semana" className="col-md-2 col-xl-1 h5 mt-2">Periodo</label>
                <div className="col-12 col-sm-7 col-md-4">
                    <select value={semanaActual} onChange={handleChange} className="form-control">
                        {opciones}
                    </select>
                </div>
            </div>            
        </div>
    )
}

export default Semana