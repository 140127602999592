import React from 'react'
import TemaRecursos from './TemaRecursos'
import TemaVideos from './TemaVideos'

function Tema({tema}) {

    return(
        <div >
            <h4 className="text-info tamano-fuente-pequeno d-flex">{tema.titulo}</h4>
            <hr className="border-info shadow"/>
            <TemaRecursos recursos={tema.recursos} />
            <TemaVideos videos={tema.videos} />
        </div>
    )
}

export default Tema