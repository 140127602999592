import React from 'react'

function Grupo({grupo, handleOnClickGrupo}) {
    return(
        <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
            <button 
            value={grupo.preguntaGrupoId}
            className="bg-info text-white text-center p-3 mt-3 rounded border grupo d-flex align-items-center justify-content-center" 
            onClick={handleOnClickGrupo}>
                {grupo.nombre}
            </button>
        </div>
    )
}

export default Grupo