import React from 'react'

function CabeceraPregunta({examenNombre, grupoNombre, volverAGrupos}) {
    return(
        <React.Fragment>
            <div className="bg-danger text-white p-3 rounded border h5 d-flex justify-content-between align-items-center">
                <p className="m-0">{examenNombre}</p>
                <button className="btn btn-info" onClick={volverAGrupos}>Materias</button>
            </div>
            <p className="text-primary h5 ml-4">{grupoNombre}</p>
            <hr/>
        </React.Fragment>



    )
}

export default CabeceraPregunta