import React from 'react'
import NoticiaFoto from './NoticiaFoto'
import { Link } from 'react-router-dom'

function NoticiaCore({noticia}){

    const url = `/NoticiaDetalle/${noticia.noticiaId}`;

    return(
        <div className="col-12 col-md-9 mt-4">
            <div className="card mb-3">
                <div className="row no-gutters">
                    <div className="col-md-4">
                        <NoticiaFoto url={noticia.foto} />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <Link className="card-title" to={url} id={noticia.noticiaId} name={noticia.noticiaId}>{noticia.titulo}</Link>
                            <p className="card-text font-raleway p-0 m-0"><small className="text-muted">{noticia.fecha}</small></p>
                            <p className="card-text font-raleway">{noticia.resumen}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoticiaCore