import React from 'react'
import { useState, useEffect } from 'react'
import { SelectBox } from 'devextreme-react';

import {
    Validator,
    RequiredRule
  } from 'devextreme-react/validator';

import Global from '../../Global'

function CboCarreraProfesional({handleValueChanged}) {

    const [items, setItems] = useState([]);

    let carreraQuePostulaId = React.createRef();

    useEffect(() => {
        fetch(Global.BASE_URL + 'api/carreras/oferta')
            .then(res => res.json())
                .then(
                    (result) => {
                        setItems(result);
                    },
                    (error) => {
                    }
            )
    }, [])

    return (
        <SelectBox 
            ref={carreraQuePostulaId}
            className="form-control" 
            id="carreraQuePostulaId"
            name="carreraQuePostulaId"
            items={items}
            valueExpr="carreraId"
            displayExpr="nombre"
            placeholder="Carrera profesional a la que postula"
            onValueChanged={handleValueChanged}>
            <Validator>
                <RequiredRule message="La carrera a la que postula es requerida." />
            </Validator>
        </SelectBox>
    )

}

export default CboCarreraProfesional
