import React from 'react'
import Navegar from './Navegar'
import Bibliotecas from './Bibliotecas'
import Pie from './Pie'

function BibliotecaAula() {
    return(
        <div className="w-100 altura-minima">
            <Navegar />
            <Bibliotecas />
            <Pie />
        </div>
    )
}

export default BibliotecaAula