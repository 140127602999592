import React from 'react'
import { Link } from 'react-router-dom'

function Examen({examen}) {

    let url = "/examendetalle/" + examen.examenId;

    return(
        <div className="card shadow mt-4">
            <div className="card-body">
                <h5 className="card-title mb-2 text-info">{examen.nombre}</h5>
                <p className="card-text mb-2">{examen.objetivo}</p>
                <p className="card-text"><em>{examen.detalle}</em></p>
                <div className="text-right">
                    <Link to={url} className="btn btn-info px-5 rounded-pill">Iniciar</Link>
                </div>
            </div>
        </div>
    )
}

export default Examen
