import React from 'react'
import { useState, useEffect } from 'react'
import { SelectBox } from 'devextreme-react';

import {
    Validator,
    RequiredRule
  } from 'devextreme-react/validator';

import Global from '../../Global'

function CboTipoPostulacion({handleValueChanged}) {

    const [items, setItems] = useState([]);

    let tipoPostulacionId = React.createRef();

    useEffect(() => {
        fetch(Global.BASE_URL + 'api/tipopostulaciones')
            .then(res => res.json())
                .then(
                    (result) => {
                        setItems(result);
                    },
                    (error) => {
                    }
            )
    }, [])

    return (
        <SelectBox 
            ref={tipoPostulacionId}
            className="form-control" 
            id="tipoPostulacionId"
            name="tipoPostulacionId"
            items={items}
            valueExpr="tipoPostulacionId"
            displayExpr="nombre"
            placeholder="Modalidad de pago"
            onValueChanged={handleValueChanged}>
            <Validator>
                <RequiredRule message="La modalidad de pago es requerido." />
            </Validator>
        </SelectBox>
    )

}

export default CboTipoPostulacion
