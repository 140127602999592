import React from 'react'
import { useState, useEffect } from 'react'
import Global from '../../Global'
import Vacante from './Vacante'

function VacantesCore() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [vacantes, setVacantes] = useState([]);
   
    useEffect(() => {
          fetch(Global.BASE_URL + 'api/carreras/oferta')
              .then(res => res.json())
                  .then(
                      (result) => {
                          setIsLoaded(true);
                          setVacantes(result);
                      },
                      (error) => {
                          setIsLoaded(false);
                          setError(error);
                      }
              ) 
    }, [])   

    if (error) {
        return (
            <div className="text-center">No se pudo cargar la página: {error.message}</div>
        ) 
    } else if (!isLoaded) {
        return (
            <div className="text-center">Cargando la página... por favor espere...</div>
        ) 
    } else {
        return(
            <div className="py-5 px-2">
                <div className="bg-white py-5 p-md-5 shadow rounded container">
                    <h2 className="text-center">VACANTES</h2>
                    <div className="mt-4">                  
                        <table className="table table-hover">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Carrera</th>
                                    <th scope="col">Vacantes</th>
                                </tr>
                            </thead>
                            <tbody>                               
                                {vacantes.map(item => ( <Vacante key={item.carreraId} oferta={item} />))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    
        )
    }
}

export default VacantesCore