import React from 'react'
function ErrorUrl() {
    return(
        <div className="container anchoContenido d-flex justify-content-center centrado">
            <div className="bg-danger text-white rounded p-5 shadow border text-center">
                <h3>Ocurrió un error al cargar la información</h3>
            </div>
        </div>
    )
}

export default ErrorUrl