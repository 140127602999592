import React, { useState, useEffect } from 'react'
import CboCarreraProfesional from '../InscripcionDx/CboCarreraProfesional'
import CboTipoPostulacion from '../InscripcionDx/CboTipoPostulacion'
import { TextBox, DateBox, SelectBox, Button } from 'devextreme-react'
import {
    Validator,
    RequiredRule,
    EmailRule,
    PatternRule,
    StringLengthRule,
    RangeRule
  } from 'devextreme-react/validator';

import notify from 'devextreme/ui/notify';
import Global from '../../Global'
import ExitoInscripcion from '../ExitoInscripcion'

function FormularioInscripcion() {

    const [resultado, setResultado] = useState({ exito: null, mensaje: ''})

    const soloTextoPattern = /^[A-Za-zÑñÁáÉéÍíÓóÚú ]*$/;
    const soloNumeroPattern = /^[0-9]*$/;
    const mensajeRequerido = "Este campo es requerido";
    const mensajeSoloTexto = "No se admite números o símbolos en este campo.";
    const mensajeSoloNumero = "Solo se admite números.";

    const currentDate = new Date();
    const maxDate = new Date(currentDate.setFullYear(currentDate.getFullYear() - 15));

    const [datos, setDatos] = useState({
        carreraQuePostulaId: null,
        dni: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        nombres: '',
        fechaNacimiento: null,
        direccion: '',
        telefono: '',
        email: '',
        tipoColegioProcedencia: 1,
        colegioProcedencia: '',
        TipoPostulacionId: 1,
        Apoderado: '',
        ApoderadoRelacion: 0,
        DniApoderado: '',
        apoderadoTelefono: ''
    });

    const handleInputChange = (e) => {
        setDatos({
            ...datos,
            [e.event.currentTarget.name] : e.event.currentTarget.value
        })
    };

    const handleValueChanged = (e) => {
        setDatos({
            ...datos,
            [e.element.id] : e.value
        })
    };    

    const handleValueChangedFecha = (e) => {

        let tiempo = Date.parse(e.value);
        if (!isNaN(tiempo)) {

            let fechaActual = new Date(tiempo);

            let dia = fechaActual.getDate();
            let mes = fechaActual.getMonth() + 1;
            let anho = fechaActual.getFullYear();

            let nuevaFecha = `${dia}/${mes}/${anho}`;

            setDatos({
                ...datos,
                [e.element.id] : nuevaFecha,
            })

        }

    };    


    const tiposColegio = [
        {id: 1, nombre: 'PUBLICO'},
        {id: 2, nombre: 'PRIVADO'},
    ];

    const sexos = [
        {id: 1, nombre: 'HOMBRE'},
        {id: 2, nombre: 'MUJER'},
    ];

    const apoderadosrelaciones = [
        {id: 1, nombre: 'Padre' },
        {id: 2, nombre: 'Madre' },
        {id: 3, nombre: 'Hermano (a)' },
        {id: 4, nombre: 'Tio (a)' },
        {id: 5, nombre: 'Primo (a)' },
        {id: 6, nombre: 'Abuelo (a)' },
        {id: 7, nombre: 'Otro' },
    ]



    const onFormSubmit = (e) => {

        let url = Global.BASE_URL + 'api/preinscribir/post';

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var miInit = { 
            method: 'POST', 
            body: JSON.stringify(datos), 
            headers: myHeaders,
        };

        fetch(url, miInit)
            .then(res => res.json())
            .then(
                (result) => {
                    setResultado(result);
                },
                (error) => {
                    setResultado(
                        {
                            exito: false,
                            mensaje: 'No se pudo enviar la inscripcion: ' + error.message
                        }
                    )
                }
            );

 
        e.preventDefault();

    };

    useEffect(() => {
        if (resultado.exito != null) {
            var tipo = (resultado.exito === true) ? 'success' : 'error'
            notify({
                message: resultado.mensaje,
                position: {
                    my: 'center top',
                    at: 'center top'
                }
            }, tipo, 3000);
        }
    }, [resultado])

    if (resultado.exito === true) {

        return (
            <ExitoInscripcion />
        )
        
    } else {

        return(

            <div className="py-5 px-2">

                <div className="container bg-white rounded-lg py-4 shadow border">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-md-10">
                            <div className="text-center">
                                <h1>INSCRÍBETE</h1>
                                <p className="text-muted font-raleway">Registra tus datos personales</p>
                            </div>

                            <div className="mt-5">
                            <form className="font-raleway" onSubmit={onFormSubmit}> 

                                    <div className="row">

                                        <div className="col-12 col-md-6">
                                            <label className="sr-only" htmlFor="carrera">Carrera de Interés</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/carrera.png" width="29px" height="29px" alt="Carrera Profesional" />
                                                    </div>
                                                </div>
                                                <CboCarreraProfesional handleValueChanged={handleValueChanged}/>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 mt-3 mt-md-0">
                                            <label className="sr-only" htmlFor="dni">DNI</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/dni.png" width="29px" height="29px" alt=""/>
                                                    </div>
                                                </div>
                                                <TextBox
                                                    className="form-control" 
                                                    name="dni" 
                                                    id="dni" 
                                                    placeholder="DNI"
                                                    maxLength="8"
                                                    onChange={handleInputChange}>
                                                    <Validator>
                                                        <RequiredRule message={mensajeRequerido} />
                                                        <StringLengthRule message="La DNI debe tener 8 digitos" min={8} max={8} />
                                                        <PatternRule message={mensajeSoloNumero} pattern={soloNumeroPattern} />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-12 col-md-6">
                                            <label className="sr-only" htmlFor="apellidoPaterno">Apellido Paterno</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/persona.png" width="29px" height="29px" alt=""/>
                                                    </div>
                                                </div>
                                                <TextBox
                                                    className="form-control" 
                                                    name="apellidoPaterno" 
                                                    id="apellidoPaterno" 
                                                    placeholder="Apellido Paterno"
                                                    maxLength="30"
                                                    onChange={handleInputChange}>
                                                    <Validator>
                                                        <RequiredRule message={mensajeRequerido} />
                                                        <StringLengthRule message="El maximo permitido es de 30 caracteres." max={30} />
                                                        <PatternRule message={mensajeSoloTexto} pattern={soloTextoPattern} />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 mt-3 mt-md-0">
                                            <label className="sr-only" htmlFor="apellidoMaterno">Apellido Materno</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/persona.png"  width="29px" height="29px" alt="" />
                                                    </div>
                                                </div>
                                                <TextBox
                                                    className="form-control" 
                                                    name="apellidoMaterno" 
                                                    id="apellidoMaterno" 
                                                    placeholder="Apellido Materno"
                                                    maxLength="30"
                                                    onChange={handleInputChange}>
                                                    <Validator>
                                                        <RequiredRule message={mensajeRequerido} />
                                                        <StringLengthRule message="El maximo permitido es de 30 caracteres." max={30} />
                                                        <PatternRule message={mensajeSoloTexto} pattern={soloTextoPattern} />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-12 col-md-6">
                                            <label className="sr-only" htmlFor="nombres">Nombres</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/persona.png"  width="29px" height="29px" alt="" />
                                                    </div>
                                                </div>
                                                <TextBox
                                                    className="form-control" 
                                                    name="nombres" 
                                                    id="nombres" 
                                                    placeholder="Nombres"
                                                    maxLength="30"
                                                    onChange={handleInputChange}>
                                                    <Validator>
                                                        <RequiredRule message={mensajeRequerido} />
                                                        <StringLengthRule message="El maximo permitido es de 30 caracteres." max={30} />
                                                        <PatternRule message={mensajeSoloTexto} pattern={soloTextoPattern} />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 col-md-6 mt-3 mt-md-0">
                                            <label className="sr-only" htmlFor="fechaNacimiento">Fecha de nacimiento</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/calendario.png"  width="29px" height="29px" alt="" />
                                                    </div>
                                                </div>
                                                <DateBox
                                                    type="date" 
                                                    className="form-control" 
                                                    name="fechaNacimiento" 
                                                    id="fechaNacimiento" 
                                                    placeholder="Fecha de nacimiento"
                                                    invalidDateMessage="La fecha debe de tener el formato: dd/MM/yyyy"
                                                    displayFormat="dd/MM/yyyy"
                                                    onValueChanged={handleValueChangedFecha}
                                                    >
                                                    <Validator>
                                                        <RequiredRule message={mensajeRequerido} />
                                                        <RangeRule message="Debe de tener por lo menos 15 años de edad" max={maxDate} />
                                                    </Validator>
                                                </DateBox>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="row mt-3">
                                        <div className="col-12 col-md-6">
                                            <label className="sr-only" htmlFor="sexo">Sexo</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/correo.png" width="29px" height="29px" alt="" />
                                                    </div>
                                                </div>
                                                <SelectBox 
                                                    className="form-control" 
                                                    id="sexo"
                                                    name="sexo"
                                                    items={sexos}
                                                    valueExpr="id"
                                                    displayExpr="nombre"
                                                    placeholder="Sexo"
                                                    onValueChanged={handleValueChanged}>
                                                    <Validator>
                                                        <RequiredRule message={mensajeRequerido} />
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 mt-3 mt-md-0">
                                            <label className="sr-only" htmlFor="tipoPostulacion">Modalidad de pago</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/tipo-escuela.png" width="29px" height="29px" alt="" />
                                                    </div>
                                                </div>
                                                <CboTipoPostulacion handleValueChanged={handleValueChanged}/>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row mt-3">
                                        <div className="col-12 col-md-6">
                                            <label className="sr-only" htmlFor="direccion">Dirección</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/ubicacion.png"  width="29px" height="29px" alt=""/>
                                                    </div>
                                                </div>
                                                <TextBox
                                                    className="form-control" 
                                                    name="direccion" 
                                                    id="direccion" 
                                                    placeholder="Dirección"
                                                    maxLength="100"
                                                    onChange={handleInputChange}>
                                                    <Validator>
                                                        <RequiredRule message={mensajeRequerido} />
                                                        <StringLengthRule message="El maximo permitido es de 30 caracteres." max={100} />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 mt-3 mt-md-0">
                                            <label className="sr-only" htmlFor="telefono">Celular/Teléfono</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/telefono.png"  width="29px" height="29px" alt="" />
                                                    </div>
                                                </div>
                                                <TextBox
                                                    className="form-control" 
                                                    name="telefono" 
                                                    id="telefono" 
                                                    placeholder="Teléfono y/o Celular"
                                                    maxLength="50"
                                                    onChange={handleInputChange}>
                                                    <Validator>
                                                        <RequiredRule message={mensajeRequerido} />
                                                        <StringLengthRule message="El maximo permitido es de 50 caracteres." max={50} />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-12 col-md-6">
                                            <label className="sr-only" htmlFor="email">Email</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/correo.png" width="29px" height="29px" alt="" />
                                                    </div>
                                                </div>
                                                <TextBox
                                                    className="form-control" 
                                                    name="email" 
                                                    id="email" 
                                                    placeholder="Correo electrónico"
                                                    maxLength="100"
                                                    onChange={handleInputChange}>
                                                    <Validator>
                                                        <RequiredRule message={mensajeRequerido} />
                                                        <StringLengthRule message="El maximo permitido es de 100 caracteres." max={100} />
                                                        <EmailRule message="La dirección de correo electrónico no es valida." />
                                                    </Validator>
                                                </TextBox>
                                                
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 mt-3 mt-md-0">
                                            <label className="sr-only" htmlFor="tipoColegio">Tipo de Colegio de Prosedencia</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/tipo-escuela.png" width="29px" height="29px" alt="" />
                                                    </div>
                                                </div>
                                                <SelectBox 
                                                    className="form-control" 
                                                    id="tipoColegioProcedencia"
                                                    name="tipoColegioProcedencia"
                                                    items={tiposColegio}
                                                    valueExpr="id"
                                                    displayExpr="nombre"
                                                    placeholder="Tipo de colegio de procedencia"
                                                    onValueChanged={handleValueChanged}>
                                                    <Validator>
                                                        <RequiredRule message={mensajeRequerido} />
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                        </div>
                                    </div>




                                    <div className="row mt-3">
                                        <div className="col-12 col-md-6">
                                            <label className="sr-only" htmlFor="colegioProcedencia">Colegio de procedencia</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/correo.png" width="29px" height="29px" alt="" />
                                                    </div>
                                                </div>
                                                <TextBox
                                                    className="form-control" 
                                                    name="colegioProcedencia" 
                                                    id="colegioProcedencia" 
                                                    placeholder="Colegio de Procedencia"
                                                    maxLength="150"
                                                    onChange={handleInputChange}>
                                                    <Validator>
                                                        <RequiredRule message={mensajeRequerido} />
                                                        <StringLengthRule message="El maximo permitido es de 150 caracteres." max={150} />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 mt-3 mt-md-0">
                                            <label className="sr-only" htmlFor="apoderado">Apoderado</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/tipo-escuela.png" width="29px" height="29px" alt="" />
                                                    </div>
                                                </div>
                                                <TextBox
                                                    className="form-control" 
                                                    name="apoderado" 
                                                    id="apoderado" 
                                                    placeholder="Apoderado"
                                                    maxLength="100"
                                                    onChange={handleInputChange}>
                                                    <Validator>
                                                        <StringLengthRule message="El maximo permitido es de 100 caracteres." max={100} />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row mt-3">
                                        <div className="col-12 col-md-6">
                                            <label className="sr-only" htmlFor="dniApoderado">DNI del apoderado</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/correo.png" width="29px" height="29px" alt="" />
                                                    </div>
                                                </div>
                                                <TextBox
                                                    className="form-control" 
                                                    name="dniApoderado" 
                                                    id="dniApoderado" 
                                                    placeholder="DNI del Apoderado"
                                                    maxLength="8"
                                                    onChange={handleInputChange}>
                                                    <Validator>
                                                        <StringLengthRule message="La DNI debe tener 8 digitos" max={8} />
                                                        <PatternRule message={mensajeSoloNumero} pattern={soloNumeroPattern} />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 mt-3 mt-md-0">
                                            <label className="sr-only" htmlFor="apoderadoRelacion">Relación con el apoderado</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/tipo-escuela.png" width="29px" height="29px" alt="" />
                                                    </div>
                                                </div>
                                                <SelectBox 
                                                    className="form-control" 
                                                    id="apoderadoRelacion"
                                                    name="apoderadoRelacion"
                                                    items={apoderadosrelaciones}
                                                    valueExpr="id"
                                                    displayExpr="nombre"
                                                    placeholder="Relacion con apoderado"
                                                    onValueChanged={handleValueChanged}>
                                                </SelectBox>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row mt-3">
                                        <div className="col-12 col-md-6">
                                            <label className="sr-only" htmlFor="apoderadoTelefono">telefono apoderado</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <img src="iconos/formulario/correo.png" width="29px" height="29px" alt="" />
                                                    </div>
                                                </div>
                                                <TextBox
                                                    className="form-control" 
                                                    name="apoderadoTelefono" 
                                                    id="apoderadoTelefono" 
                                                    placeholder="Teléfono del apoderado"
                                                    maxLength="50"
                                                    onChange={handleInputChange}>
                                                    <Validator>
                                                        <StringLengthRule message="El maximo permitido es de 50 caracteres." max={50} />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 mt-3 mt-md-0">
                                         </div>
                                    </div>


                                    <div className="text-center mt-4">
                                        <Button
                                            width={120}
                                            text="Inscribirme"
                                            type="success"
                                            stylingMode="contained"
                                            useSubmitBehavior={true}
                                        />
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



export default FormularioInscripcion