import React from 'react'

function Vacante({oferta}) {
    return(
        <tr>
            <td>{oferta.nombre}</td>
            <td className="text-center">{oferta.vacante}</td>
        </tr>
    )
}

export default Vacante