import React from 'react'
import InfoWeb from '../InfoWeb'

function Pie() {
    return(
        <InfoWeb.Consumer>
            {info => (
                <div className="navbar-color text-white py-4">
                    <div className="container">
                        <h3>Contáctanos</h3>
                        <div className="row mt-3">
                            <div className="col-12 col-md-4">
                                <p className="font-raleway border bg-celeste rounded p-2 text-center">
                                    <img src="/iconos/footer/telefono.png" width="30px" className="pr-2" alt="telefono" />
                                    {info.telefono}
                                </p>
                            </div>
                            <div className="col-12 col-md-4">
                                <p className="font-raleway border rounded bg-verde p-2 text-center">
                                    <img src="/iconos/footer/whatsapp.png" width="30px" className="pr-2" alt="whatsapp" />
                                    {info.whatsapp}
                                </p>
                            </div>
                            <div className="col-12 col-md-4">
                                <p className="font-raleway border rounded p-2 bg-naranja text-center">
                                    <img src="/iconos/footer/correo.png" width="30px" className="pr-2" alt="correo"/>
                                    {info.correo}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </InfoWeb.Consumer>
    )
}

export default Pie