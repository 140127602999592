import React from 'react';
import { useContext } from 'react';
import { Link  } from 'react-router-dom'; 

import UsuarioContext from '../UsuarioContext'

function Navegar() {

    const [ estadoUsuario, setEstadoUsuario ] = useContext(UsuarioContext);

    const handleClicCerrarSesion = () => {
        setEstadoUsuario({
            exito: false,
            token: null,
            estudiante: null
        });
    }

    return(
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark navbar-color">
                <div className="container">
                    <Link className="navbar-brand" to="#">
                        <div className="row">
                            <div className="col-3 col-sm-3">
                                <img src="/logo-untrm.png" className="d-inline-block align-top logo-untrm" alt="Untrm" />
                            </div>
                            <div className="col-7 col-sm-9 d-flex align-items-center">
                                <div className="fuente-navbar-logo">
                                    Centro Pre Universitario <br /> UNTRM
                                </div>
                            </div>
                        </div>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                        <li className="nav-item active">
                                <Link className="nav-link fuente-navbar" to="/">Inicio</Link>
                            </li>
                            <li className="nav-item active">
                                <Link className="nav-link fuente-navbar" to="/aulavirtual">Aula</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link fuente-navbar" to="/bibliotecaaula">Biblioteca</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link fuente-navbar" to="/evaluacion">Evaluacion</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link fuente-navbar" to="/evaluaciones">Mis evaluaciones</Link>
                            </li>
                            <li className="hr-vertical"></li>
                            <li className="nav-item dropdown">
                                <div className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div className="btn btn-danger">{estadoUsuario.estudiante.nombreCorto}</div>
                                </div>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <div className="dropdown-item" href="#">
                                    <Link className="nav-link fuente-navbar" to="#">
                                        <div href="#" className="text-dark"
                                        onClick={handleClicCerrarSesion}>Cerrar sesión</div>
                                    </Link>
                                    <Link className="nav-link text-dark" to="/cambiarclave">
                                        Cambiar contraseña
                                    </Link>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item d-flex justify-content-center">
                                
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navegar