import React from 'react'
import { Link } from 'react-router-dom'
import InfoWeb from '../InfoWeb'

function Slider() {

     return(
        <InfoWeb.Consumer>
            {info => (
        <div id="slider-principal" className="carousel slide"  data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active carousel-img">
                    <div className="container my-4">
                        <div className="row">
                            <div className="col-12 col-md-5 mr-md-5">
                                <div className="card card-tamanho">
                                    <h5 className="card-header">Universidad Nacional Toribio Rodriguez de Mendoza</h5>
                                    <div className="card-body">
                                        <h5 className="card-title">Inscripciones</h5>
                                        <p className="card-text font-raleway">{info.mensajeInscripcion}</p>
                                        <div className="d-flex justify-content-center w-100">
                                            <Link to="/vacantes" className="btn text-white bg-success rounded-pill font-raleway px-5">Inscribirme</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 mt-5 mt-md-5 text-center text-white text-shadow d-flex align-items-center">
                                <div className="w-100">
                                    <h1><strong>Ciclo Académico</strong></h1>
                                    <h3>{info.cicloAcademico}</h3>
                                    <p className="fuente-navbar font-raleway">{info.mensajeCicloAcademico}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )}
        </InfoWeb.Consumer>
    )
}

export default Slider