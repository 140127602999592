import React from 'react'
import Navegacion from './Navegacion'
import Pie from './Pie'
import Slider from './Slider'
import Noticias from './Noticias/Noticias'
import Ventajas from './Ventajas/Ventajas'
import FormularioInscripcion from './Inscripcion/FormularioInscripcion'

function Home() {
    return (
        <div className="w-100 fondo-body">
            <Navegacion />
            <Slider />
            <Noticias />
            <Ventajas />
            <FormularioInscripcion />
            <Pie />
        </div>
    )
}

export default Home;