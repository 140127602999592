import React from 'react'
import TemaRecurso from './TemaRecurso'

function TemaRecursos({recursos}) {

    return(
        <div className="font-raleway lead mt-4">
            <h5 className="text-info">Recursos disponibles</h5>
            <div className="container">
                {recursos.map(r => (
                    <TemaRecurso key={r.temaRecursoId} recurso={r} />
                ))}
            </div>
        </div>
    )
}

export default TemaRecursos