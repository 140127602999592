import React from 'react'

function ExitoInscripcion() {

    return(
        <div className="py-5 px-2">
            <div className="container my-5 alert alert-success text-center rounded-lg py-4 shadow border">
                <img src="iconos/exito.png" width="130px" alt="Exito" />
                <h5 className="font-raleway mt-3">Tu inscripción se realizó con éxito</h5>
                <p className="font-raleway mt-3">Hemos enviado un email a tu correo electrónico con indicaciones de lo que debes hacer. Si no está en tu bandeja de entrada, revisa en el Correo no deseado.</p>
            </div>
        </div>
    )

}

export default ExitoInscripcion
