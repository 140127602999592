import React from 'react'
import { useState, useEffect } from 'react'
import Global from '../../Global'
import Navegacion from '../Navegacion'
import Pie from '../Pie'

function NoticiaDetalle({ match }) {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [noticia, setNoticia] = useState([]);

    useEffect(() => {

          let url = Global.BASE_URL + "api/noticias/" + match.params.id;

          fetch(url)
              .then(res => res.json())
                  .then(
                      (result) => {
                          setIsLoaded(true);
                          setNoticia(result);
                      },
                      (error) => {
                          setIsLoaded(false);
                          setError(error);
                      }
              ) 
    }, [])    

    let urlFoto = Global.BASE_URL + 'api/archivos/image/' + noticia.foto;

    if (error) {
        return (
            <div className="text-center">No se pudo mostrar la noticia. Error: {error.message}</div>
        )
    } else if (!isLoaded) {
        return (
            <div className="text-center">Intentando mostrar la noticia.. por favor espere...</div>
        )
    } else {  
        return(
            <div className="w-100 fondo-body">
                <Navegacion />
                <div className="py-5">
                    <div className="bg-white shadow rounded py-5 p-md-5 container">
                        <h3>{noticia.titulo}</h3>
                        <hr/>
                        <div className="row d-flex justify-content-center">
                            <div className="col-7">
                                <img src={urlFoto} className="w-100" alt="foto" />
                            </div>
                        </div>

                        <div className="font-raleway mt-5" dangerouslySetInnerHTML={{__html: noticia.contenido}} >
                        </div>
                    </div>
                </div>
                <Pie />
            </div>

        )
    }
}

export default NoticiaDetalle