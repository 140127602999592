import React from 'react'
import NoticiaCore from './NoticiaCore'

function NoticiasCore ({lista}) {

    return(

        <div className="row mt-4 d-flex justify-content-center">
            {lista.map(item => (
                <NoticiaCore key={item.noticiaId} noticia={item} />
            ))}
        </div>

    )
}

export default NoticiasCore