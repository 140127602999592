import React from 'react'

function Cargando() {

    return(
        <div className="text-center centrado">
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Cargando</span>
            </div>
            <h3>Intentando mostrar el contenido... por favor espere...</h3>
        </div>
    )
}

export default Cargando