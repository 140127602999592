import React from 'react'
import PublicacionRecursos from './PublicacionRecursos'
import PublicacionVideos from './PublicacionVideos'

function Publicacion({publicacion}) {

    return(
        <div >
            <h4 className="text-info tamano-fuente-pequeno d-flex">{publicacion.titulo}</h4>
            <hr className="border-info shadow"/>
            <PublicacionRecursos recursos={publicacion.recursos} />
            <PublicacionVideos videos={publicacion.videos} />
        </div>
    )
}

export default Publicacion
