import React from 'react'
import { useState, useEffect, useContext } from 'react'
import UsuarioContext from '../../UsuarioContext'
import Global from '../../Global'
import Instrumento from './Instrumento'
import Cargando from '../Cargando'
import ErrorUrl from '../ErrorUrl';


function Instrumentos() {


    const [ estadoUsuario ] = useContext(UsuarioContext);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [instrumentos, setInstrumentos] = useState([]);

    useEffect(() => {

        let url = Global.BASE_URL + 'api/evaluaciones';

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${estadoUsuario.token}`);

        var miInit = { 
            method: 'GET', 
            headers: myHeaders,
        };

        fetch(url, miInit)
            .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setInstrumentos(result);
                    },
                    (error) => {
                        setIsLoaded(false);
                        setError(error);
                    }
            ) 
    }, [])   
     
    if (error) {
        return (
            <ErrorUrl />
        ) 
    } else if (!isLoaded) {
        return (
            <Cargando />
        ) 
    } else {
        return(
            <div className="container mt-1 mb-5">
                {instrumentos.map(i => (
                    <Instrumento key={i.instrumentoId} instrumento={i} />
                ))}
            </div>
        )
    }
}

export default Instrumentos