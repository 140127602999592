import React from 'react'
import ExamenRespuesta from './ExamenRespuesta'

function ExamenAplicado({examen, alternativas, handleChange, handleEnviarRespuestas}) {

    return (

        <React.Fragment>
            <div className="container my-5">
                <p className="bg-danger text-white p-3 rounded border h5">{examen.nombre}</p>
                <div className="row">
                    {alternativas.map(x => ( <ExamenRespuesta key={x.pregunta} numeroPregunta={x.pregunta} alternativa={x.alternativa} handleChange={handleChange} /> ))}
                </div>
            </div>
            <div className="text-center pb-5">
            <button value="0" onClick={handleEnviarRespuestas} className="btn btn-success px-4 mx-4">Guardar respuestas</button>
            </div>
        </React.Fragment>
    )
    
}

export default ExamenAplicado;