import React, { useContext, useState } from 'react'
import UsuarioContext from '../UsuarioContext'
import Global from '../Global'

function Login({history}) {

    const [ estadoUsuario, setEstadoUsuario ] = useContext(UsuarioContext)

    const [datos, setDatos] = useState({
        usuario: '',
        password: '',
    });

    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name] : event.target.value
        })
    };

    const onFormSubmit = (e) => {

        

        let url = Global.BASE_URL + 'api/login';

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var miInit = { 
            method: 'POST', 
            body: JSON.stringify(datos), 
            headers: myHeaders,
        };

        fetch(url, miInit)
            .then(respuesta => {
                if (respuesta.ok) {
                    return respuesta;
                } 
            })
            .then(res => res.json())
            .then(
                (result) => {
                    setEstadoUsuario(
                        {
                            exito: true,
                            token: result.token,
                            estudiante: result.estudiante
                        }
                    );
                    history.push("/aulavirtual");      
                },
                (error) => {
                    setEstadoUsuario(
                        {
                            exito: false,
                            token: null,
                            estudiante: null
                        }
                    )                       
                }
            );

 
        e.preventDefault();

    };

    return(
            <div className="container px-md-5 mt-5 mb-5">
                <div className="card mx-md-5 mt-md-5">
                    <div className="d-flex justify-content-center mt-4">
                        <img src="logo-untrm.png" width="160px" alt="Logo Untrm" />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title text-center text-primary">Universidad Nacional Toribio Rodriguez de Mendoza</h5>
                        <h5 className="card-title text-center text-primary">Centro Preuniversitario</h5>
                        <form onSubmit={onFormSubmit}>
                            <div className="form-row mt-4 d-flex justify-content-center">
                                <div className="col-12 col-sm-10 col-md-7">
                                    <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">U</div>
                                        </div>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="usuario" 
                                            name="usuario"
                                            placeholder="Usuario"
                                            autoComplete="off"
                                            value={datos.usuario}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="input-group my-4 mr-sm-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">P</div>
                                        </div>
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            id="password" 
                                            name="password"
                                            autoComplete="new-password"
                                            placeholder="Password"
                                            value={datos.password}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    
                                    <div className="w-100 d-flex justify-content-center">
                                        <input type="submit" className="btn btn-primary rounded-pill px-4" value="Iniciar Sesión"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    )
}

export default Login