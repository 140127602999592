import React from 'react'
import ExamenArchivo from './ExamenArchivo'

function ExamenConfirmado({examen, handleOnClickAcepto}) {
    return(
        <div className="container mt-5 mb-5">
            <p className="bg-danger text-white p-3 rounded border h5">{examen.nombre}</p>
            <div className="ml-3 mt-3">
                <div>
                    <p className="text-info h5 anchoContenido">Objetivo</p>
                    <p>
                        {examen.objetivo}
                    </p>
                </div>
                <div className="mt-5">
                    <p className="text-info h5 anchoContenido">Respecto al tiempo</p>
                    <p>
                        {examen.detalle}
                    </p>
                </div>
                <div className="mt-5">

                    <p className="text-info h5 anchoContenido">Descargar el examen</p>
                    <div>
                        <p>Descargue el examen y una vez que lo haya desarrollado, pulse clic en el botón responder preguntas.</p>
                        <ExamenArchivo examen={examen} />
                    </div>

                </div>
            </div>
            <div className="text-center mt-4">
                <button className="btn btn-danger px-4 rounded-pill" onClick={handleOnClickAcepto}>Responder preguntas del examen</button>
            </div>
        </div>
    )
}

export default ExamenConfirmado

