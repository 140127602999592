import React from 'react'
import { useState, useEffect, useContext } from 'react'
import UsuarioContext from '../../UsuarioContext'
import Global from '../../Global'
import ErrorUrl from '../ErrorUrl'
import Cargando from '../Cargando'
import Navegar from '../Navegar'
import Pie from '../Pie'
import InstrumentoConfirmado from './InstrumentoConfirmado'
import InstrumentoAplicado from './InstrumentoAplicado'
import PreguntaAplicada from './PreguntaAplicada'
import MensajeInformacion from './MensajeInformacion'

function InstrumentoDetalle({ match }) {

    const [ estadoUsuario ] = useContext(UsuarioContext);

    const [error, setError] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [instrumento, setInstrumento] = useState({
                                                        instrumentoId : -1,
                                                        nombre: '',
                                                        objetivo: '',
                                                        detalle: ''
                                                    });

    const estudianteInstrumentoInicial = {

        exito: false,
        mensaje: "",
        evaluacion: {
            estudianteInstrumentoId: -1,
            instrumentoId: -1,
            nombre: '',
            fecha: '',
            horaInicio: '',
            minutosTranscurridos: 0,
            horaTermino: '',
            grupos: []
        }
    }

    const [estudianteInstrumento, setEstudianteInstrumento] = useState(estudianteInstrumentoInicial);

    const [paso, setPaso] = useState(1);
    const [grupoSeleccionadoId, setGrupoSeleccionadoId] = useState(-1);

    const setDataExamen = (value) => {
        setEstudianteInstrumento(value);
        setPaso(2);
    };


    useEffect(() => {

        if (paso === 1) {

            let url = Global.BASE_URL + 'api/evaluaciones/' + match.params.id;

            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${estadoUsuario.token}`);

            var miInit = { 
                method: 'GET', 
                headers: myHeaders,
            };

            fetch(url, miInit)
            .then(respuesta => {
                if (respuesta.ok) {
                    return respuesta;
                };
            })
            .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setInstrumento(result);
                    },
                    (error) => {
                        setIsLoaded(false);
                        setError(error);
                        setInstrumento(null);
                    }
            );
        }

    }, []);


    const handleOnClickAcepto = (e) => {

            setIsLoaded(false)

            let url = Global.BASE_URL + 'api/evalestudiante';

            let datos = {
                instrumentoId: instrumento.instrumentoId
            };

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${estadoUsuario.token}`);
    
            var miInit = { 
                method: 'POST', 
                body: JSON.stringify(datos), 
                headers: myHeaders,
            };
    
            fetch(url, miInit)
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setDataExamen(result);
                    },
                    (error) => {
                        setIsLoaded(false);
                        setError(error);
                        setEstudianteInstrumento(estudianteInstrumentoInicial)
                    }
                );

            e.preventDefault();
    
        };

    const handleOnClickGrupo = (e) => {
        setGrupoSeleccionadoId(e.target.value);
        setPaso(3);
    }

    const volverAGrupos = () => {
        setPaso(2);
    }


    if (error) {
        return (
            <ErrorUrl />
        )
    } else if (!isLoaded) {
        return (
            <Cargando />
        )
    } else { 
        switch(paso) {
            case 1:
                return (
                    <React.Fragment>
                        <Navegar />
                        <InstrumentoConfirmado instrumento={instrumento} handleOnClickAcepto={handleOnClickAcepto} />
                        <Pie />
                    </React.Fragment>
                );
            case 2:
                //acepto las condiciones y ahora tenemos que mostrar el contenido del examen
                if (estudianteInstrumento.exito === true) {
                    return(
                        <React.Fragment>
                            <Navegar />
                            <InstrumentoAplicado estudianteInstrumento={estudianteInstrumento.evaluacion} handleOnClickGrupo={handleOnClickGrupo} />
                            <Pie />
                        </React.Fragment>
                    )
                } else {
                    return(
                        <React.Fragment>
                            <Navegar />
                            <MensajeInformacion mensaje={estudianteInstrumento.mensaje} urlDestino={"/evaluacion"} />
                            <Pie />
                        </React.Fragment>
                    )                    
                }

            case 3:
                if (grupoSeleccionadoId > 0) {
                    return(
                        <React.Fragment>
                            <Navegar />
                            <PreguntaAplicada 
                            estudianteInstrumento={estudianteInstrumento.evaluacion} 
                            grupoId={grupoSeleccionadoId}
                            volverAGrupos={volverAGrupos}/>
                            <Pie />
                        </React.Fragment>
                    )    
                } else {
                    return (
                        <React.Fragment>
                            <Navegar />
                            <ErrorUrl />
                            <Pie />
                        </React.Fragment> 
                    )
                }
            default:
                return (
                    <Cargando />
                )
        }
        
    }
}

export default InstrumentoDetalle