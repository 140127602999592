import React from 'react'
import Navegar from './Navegar'
import Cursos from './Cursos'
import Pie from './Pie'

function Aula() {
    return(
        <React.Fragment>
            <Navegar />
            <Cursos />
            <Pie />
        </React.Fragment>
    )
}

export default Aula