import React from 'react'
import { useState, useEffect, useContext } from 'react'
import UsuarioContext from '../../UsuarioContext'
import Global from '../../Global'
import ErrorUrl from '../ErrorUrl'
import Cargando from '../Cargando'
import Navegar from '../Navegar'
import Pie from '../Pie'
import ExamenConfirmado from './ExamenConfirmado'
import ExamenAplicado from './ExamenAplicado'
import MensajeExito from './MensajeExito'
import notify from 'devextreme/ui/notify';

function ExamenDetalle({ match }) {

    const [ estadoUsuario ] = useContext(UsuarioContext);

    const [error, setError] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [examen, setExamen] = useState({
                                                        ExamenId : -1,
                                                        nombre: '',
                                                        objetivo: '',
                                                        detalle: ''
                                                    });

    const alternativasInicial = [];

    const [alternativas, setAlternativas] = useState(alternativasInicial);

    const [paso, setPaso] = useState(1);

    const [respuesta, setRespuesta] = useState();

    const setDataExamen = (value) => {
        setAlternativas(value);
        setPaso(2);
    };

    const setDataRespuesta = (value) => {
        console.log(value);
        setRespuesta(value);
        if (value.exito === true)
        {
            setPaso(3);
        } else {
            //mostrando la notificacion
            notify({
                message: value.mensaje,
                position: {
                    my: 'center top',
                    at: 'center top'
                }
            }, "error", 2000);
        }       
    };


    const handleChange = (e) => {

        const actualSelecciónIndice = alternativas.findIndex(el => {
          return el.pregunta === Number(e.target.name)
        })
    
        let nuevaAlternativas = [...alternativas]
    
        if(actualSelecciónIndice >= 0) {
          nuevaAlternativas[actualSelecciónIndice].alternativa = Number(e.target.value)
          setAlternativas(nuevaAlternativas)
        }

    }

    useEffect(() => {

        if (paso === 1) {

            let url = Global.BASE_URL + 'api/examenes/' + match.params.id;

            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${estadoUsuario.token}`);

            var miInit = { 
                method: 'GET', 
                headers: myHeaders,
            };

            fetch(url, miInit)
            .then(respuesta => {
                if (respuesta.ok) {
                    return respuesta;
                };
            })
            .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setExamen(result);
                    },
                    (error) => {
                        setIsLoaded(false);
                        setError(error);
                        setExamen(null);
                    }
            );
        }

    }, []);

    const handleOnClickAcepto = (e) => {

            setIsLoaded(false)

            let url = Global.BASE_URL + 'api/examestudiante/' + examen.examenId;

            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${estadoUsuario.token}`);
    
            var miInit = { 
                method: 'GET', 
                headers: myHeaders,
            };
    
            fetch(url, miInit)
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setDataExamen(result);
                    },
                    (error) => {
                        setIsLoaded(false);
                        setError(error);
                        setAlternativas(alternativasInicial)
                    }
                );

            e.preventDefault();
    
        };


const handleEnviarRespuestas = (e) => {

    setIsLoaded(false)

    let datos = {
        respuestas: alternativas,
        examenId: examen.examenId,
    };

    let url = Global.BASE_URL + 'api/examestudianteresponder';

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${estadoUsuario.token}`);

    var miInit = { 
        method: 'POST', 
        body: JSON.stringify(datos), 
        headers: myHeaders,
    };

    fetch(url, miInit)
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                setDataRespuesta(result);
            },
            (error) => {
                setIsLoaded(false);
                setError(error);
                setAlternativas(alternativasInicial)
            }
        );

        e.preventDefault();
    };


    if (error) {
        return (
            <ErrorUrl />
        )
    } else if (!isLoaded) {
        return (
            <Cargando />
        )
    } else { 
        switch(paso) {
            case 1:
                return (
                    <React.Fragment>
                        <Navegar />
                        <ExamenConfirmado examen={examen} handleOnClickAcepto={handleOnClickAcepto} />
                        <Pie />
                    </React.Fragment>
                );
            case 2:
                //acepto las condiciones y ahora tenemos que mostrar el contenido del examen
                return(
                    <React.Fragment>
                        <Navegar />
                        <ExamenAplicado examen={examen}  alternativas={alternativas} handleChange={handleChange} handleEnviarRespuestas={handleEnviarRespuestas} />
                        <Pie />
                    </React.Fragment>
                )
            case 3:
                if (respuesta.exito === true) {
                    return(
                        <React.Fragment>
                            <Navegar />
                            <MensajeExito mensaje={respuesta.mensaje} urlDestino={"/evaluacion"} />
                            <Pie />
                        </React.Fragment>
                    )  
                }
            default:
                return (
                    <Cargando />
                )
        }
        
    }
}

export default ExamenDetalle

