import React from 'react'
import TemaVideo from './TemaVideo'

function TemaVideos({videos}) {

    return(
        <div className="font-raleway lead mt-4">
            <h5 className="text-info">Videos explicativos</h5>
            <div className="container">
                {videos.map(v => (
                    <TemaVideo key={v.temaVideoId} video={v} />
                ))}
            </div>
        </div>
    )
}

export default TemaVideos
