import React from 'react'
import Publicacion from './Publicacion'

function Publicaciones({publicaciones}) {

    return (
        <div className="mt-4">
            {publicaciones.map((t) => (
                <Publicacion key={t.publicacionId} publicacion={t}/>
            ))}
        </div>
    )

}

export default Publicaciones

