import React from 'react'
import Grafico from './Grafico'

function Respuesta({respuesta, handledOnClickRespuesta}) {

    let id = respuesta.respuestaId;
    let name = respuesta.preguntaId;

    return (
        <div className="custom-control custom-radio mt-4">
            <input 
            type="radio" 
            id={id} 
            name={name} 
            className="custom-control-input" 
            value={respuesta.respuestaId}
            onClick={handledOnClickRespuesta} />
            <label className="custom-control-label" htmlFor={id}>
                {respuesta.enunciado}
                <Grafico item={respuesta} />
            </label>

        </div>
    )
}

export default Respuesta