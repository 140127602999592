import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'; 

import './App.css';

import Global from './Global'
import Home from './Componentes/Home'
import Mision from './Componentes/Mision'
import Tramites from './Componentes/Tramites'
import EquipoTrabajo from './Componentes/EquipoTrabajo'
import Vacantes from './Componentes/Vacantes/Vacantes'
import NoticiaDetalle from './Componentes/Noticias/NoticiaDetalle'
import InstrumentoDetalle from './Componentes/Evaluacion/InstrumentoDetalle'
import ExamenDetalle from './Componentes/Evaluacion/ExamenDetalle'

import PrivateRoute from './PrivateRoute'
import Aula from './Componentes/Aula'
import CursoDetalle from './Componentes/CursoDetalle'
import BibliotecaAula from './Componentes/BibliotecaAula'
import BibliotecaDetalle from './Componentes/BibliotecaDetalle'
import Login from './Componentes/Login';

import UsuarioContext from './UsuarioContext'
import AulaContext from './AulaContext'
import InfoWeb from './InfoWeb'
import ErrorUrl from './Componentes/ErrorUrl';
import Cargando from './Componentes/Cargando';
import InstrumentosPage from './Componentes/Evaluacion/InstrumentosPage';
import CambiarClave from './Componentes/CambiarClave';
import EstudianteEvaluaciones from './Componentes/EstudianteEvaluaciones'

const estadoInicialUsuario = {
  exito: false,
  token: null,
  estudiante: null
}

const estadoInicialAula = {
  cursos: [],
  evaluaciones: [],
  bibliotecas: []
}

function App() {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [info, setInfo] = useState([]);

  const [ estadoUsuario, setEstadoUsuario ] = useState(estadoInicialUsuario)

  const [ estadoAula, setEstadoAula ] = useState(estadoInicialAula)
 
  useEffect(() => {
        fetch(Global.BASE_URL + 'api/webinfo/home')
            .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setInfo(result);
                    },
                    (error) => {
                        setIsLoaded(false);
                        setError(error);
                    }
            ) 
  }, [])    

  if (error) {
    return (
      <ErrorUrl />
    )
  } else if (!isLoaded) {
    return (
      <Cargando />
    )
  } else {
    return (
      <UsuarioContext.Provider value={ [ estadoUsuario, setEstadoUsuario ] } >
        <InfoWeb.Provider value={info}>
          <AulaContext.Provider value={ [ estadoAula, setEstadoAula ] } >
            <UsuarioContext.Consumer>
              {estadoUsuario => (
                <AulaContext.Consumer>
                  {estadoAula => (
                    <BrowserRouter>
                      <Switch >
                        <Route exact path='/' component={Home} />
                        <Route exact path='/mision' component={Mision} />
                        <Route exact path='/tramites' component={Tramites} />
                        <Route exact path='/equipo' component={EquipoTrabajo} />
                        <Route exact path='/vacantes' component={Vacantes} />
                        <Route exact path='/noticiadetalle/:id' component={NoticiaDetalle} />
                        <PrivateRoute path="/aulavirtual" exact component={Aula} />
                        <PrivateRoute path="/cursodetalle/:id/:nombre" exact component={CursoDetalle} />
                        <PrivateRoute path="/bibliotecaaula" component={BibliotecaAula} />
                        <PrivateRoute path="/bibliotecadetalle/:id/:nombre" exact component={BibliotecaDetalle} />
                        <PrivateRoute path="/instrumentodetalle/:id" exact component={InstrumentoDetalle} />
                        <PrivateRoute path="/examendetalle/:id" exact component={ExamenDetalle} />
                        <PrivateRoute path="/evaluacion" component={InstrumentosPage} />
                        <PrivateRoute path="/evaluaciones" component={EstudianteEvaluaciones} />
                        <PrivateRoute path="/cambiarclave" component={CambiarClave} />
                        <Route path="/login" component={Login} />
                        <Route exact path='*' component={Home} />
                      </Switch >
                    </BrowserRouter>
                  )}
                </AulaContext.Consumer>
              )}
            </UsuarioContext.Consumer>
          </AulaContext.Provider>
        </InfoWeb.Provider>
      </UsuarioContext.Provider>
    );
  }
}

export default App;
