import React from 'react'
import { Link } from 'react-router-dom'

function MensajeExito({mensaje, urlDestino}) {

    return(
        <div className="container anchoContenido d-flex justify-content-center centrado">
            <div className="bg-success text-white rounded p-5 shadow border text-center">
                <p>{mensaje}</p>
                <Link to={urlDestino} className="btn btn-dark">Volver a los exámenes</Link>
            </div>
        </div>
    )
}

export default MensajeExito