import React from 'react'
import InfoWeb from '../InfoWeb'

function Calendario() {

    return(
        <InfoWeb.Consumer>
            {info => (
                <div className="py-5 px-2">
                    <div className="container bg-white py-4 rounded-lg border shadow">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-md-9">
                                <div className="row">
                                    <div className="col-6 text-center">
                                        <img src="iconos/calendario.png" width="110px" height="95px" alt="calendario" />
                                        <div className="mt-3">
                                            <h4>Inicio y Término del ciclo</h4>
                                            <p>{info.inicioCiclo}</p>
                                        </div>
                                    </div>
                                    <div className="col-6 text-center">
                                        <img src="iconos/dinero.png" width="110px" height="95px" alt="costo" />
                                        <div className="mt-3">
                                            <h4>Costo de la enseñanza</h4>
                                            <p>{info.costoEnsenhanza}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </InfoWeb.Consumer>
    )
}

export default Calendario