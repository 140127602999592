import React from 'react'
import InfoWeb from '../../InfoWeb'

function VentajasCore({info}) {
    return(
        <InfoWeb.Consumer>
        {info => (
            <div className="container">
                <div className="row mt-5">
                    <div className="col-12 col-md-4 text-center">
                        <img src="iconos/capacitacion.png" width="110px" height="110px" alt="capacitacion" />
                        <div className="mt-5">
                            <p className="fuente-navbar"><strong>{info.ventaja1Titulo}</strong></p>
                            <p className="font-raleway">{info.ventajaDetalle1}</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mt-5 mt-md-0 text-center">
                        <img src="iconos/evaluacion.png" width="100px" height="110px" alt="evaluacion" />
                        <div className="mt-5">
                            <p className="fuente-navbar"><strong>{info.ventaja2Titulo}</strong></p>
                            <p className="font-raleway">{info.ventajaDetalle2}</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mt-5 mt-md-0 text-center">
                        <img src="iconos/acceso-directo.png" width="100px" height="110px" alt="acceso directo" />
                        <div className="mt-5">
                            <p className="fuente-navbar"><strong>{info.ventaja3Titulo}</strong></p>
                            <p className="font-raleway">{info.ventajaDetalle3}</p>
                        </div>
                    </div>
                </div>
            </div>
        )}
        </InfoWeb.Consumer>
    )    
}

export default VentajasCore

