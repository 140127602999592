import   React from 'react'
import Global from '../../Global'

function NoticiaFoto({url}) {

    let urlFoto = Global.BASE_URL + 'api/archivos/image/' + url;

    return(
        <img src={urlFoto} alt="foto" className="card-img" />
    )

}

export default NoticiaFoto