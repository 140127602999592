import React from 'react'
import { useState } from 'react';

function ExamenRespuesta({numeroPregunta, alternativa, handleChange}) {

    return(

        <div className="ancho-alternativa mx-3 mb-2 border p-1 rounded d-flex align-items-center row">
                <div className="col-5 p-1">
                    pregunta {numeroPregunta}: 
                </div>

                <div className="col-7">
                    <select value={alternativa} id={numeroPregunta} name={numeroPregunta} onChange={handleChange} className="form-control">
                        <option value="0">vacio</option>    
                        <option value="1">a</option>    
                        <option value="2">b</option>
                        <option value="3">c</option>
                        <option value="4">d</option>
                        <option value="5">e</option>
                    </select>
                </div>
        </div>

    )

}

export default ExamenRespuesta;