import React from 'react'
import Respuesta from './Respuesta'
import Grafico from './Grafico'

function Pregunta({item, handledOnClickRespuesta}) {

    return (
        <React.Fragment>
            <div className="ml-5">  
                <p className="text-info h5">{item.numero}</p>
                <p className="text-raleway text-justify">{item.enunciado}</p>
            </div>
            <div className="row d-flex justify-content-center">
                <Grafico item={item} />
            </div>

            <div className="mt-5 ml-5">
                {item.respuestas.map(r => (<Respuesta key={r.respuestaId} respuesta={r} handledOnClickRespuesta={handledOnClickRespuesta} />))}
            </div>
        </React.Fragment>
    )
}

export default Pregunta