import React from 'react'
import Tema from './Tema'

function Temas({temas, semanaActual}) {

    return (
        <div className="mt-4">
            {temas
                .filter(f => parseInt(f.semana) === parseInt(semanaActual))
                .map((t) => (<Tema key={t.temaId} tema={t}/>))}
        </div>
    )

}

export default Temas