import React from 'react'
import Navegar from  '../Navegar'
import Pie from '../Pie'
import Instrumentos from './Instrumentos'
import Examenes from './Examenes'

function InstrumentosPage() {
    return(
        <React.Fragment>
            <Navegar />
            <Instrumentos />
            <Examenes />
            <Pie />
        </React.Fragment>
    )
}

export default InstrumentosPage