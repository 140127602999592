import React from 'react'
import { Link } from 'react-router-dom'

function Navegacion() {
    return(
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark navbar-color">
                <div className="container">
                    <Link className="navbar-brand" to="#">
                        <div className="row">
                            <div className="col-3 col-sm-3">
                                <img src="/logo-untrm.png" className="d-inline-block align-top logo-untrm" alt="Untrm" />
                            </div>
                            <div className="col-7 col-sm-9 d-flex align-items-center">
                                <div className="fuente-navbar-logo">
                                    Centro Pre Universitario <br /> UNTRM
                                </div>
                            </div>
                        </div>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <Link className="nav-link fuente-navbar" to="/">Inicio</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle fuente-navbar" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Información
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/mision">Misión y Visión</Link>
                                    <Link className="dropdown-item" to="/tramites">Trámites</Link>
                                    <Link className="dropdown-item" to="/equipo">Equipo de Trabajo</Link>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link fuente-navbar" to="/vacantes">Vacantes</Link>
                            </li>
                            <li className="nav-item d-flex justify-content-center">
                                <Link className="nav-link fuente-navbar btn btn-success px-4 ml-3 text-white rounded-pill" to="/aulavirtual">Aula virtual</Link>
                            </li>
                         </ul>
                    </div>
                </div>
            </nav>
        </div>
    )

}

export default Navegacion