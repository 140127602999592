import React from 'react'
import { useState, useEffect, useContext } from 'react'
import UsuarioContext from '../../UsuarioContext'
import Global from '../../Global'
import Examen from './Examen'
import Cargando from '../Cargando'
import ErrorUrl from '../ErrorUrl';

function Examenes() {

    const [ estadoUsuario ] = useContext(UsuarioContext);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [examenes, setExamenes] = useState([]);

    useEffect(() => {

        let url = Global.BASE_URL + 'api/examenes';

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${estadoUsuario.token}`);

        var miInit = { 
            method: 'GET', 
            headers: myHeaders,
        };

        fetch(url, miInit)
            .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setExamenes(result);
                    },
                    (error) => {
                        setIsLoaded(false);
                        setError(error);
                    }
            ) 
    }, [])   
     
    if (error) {
        return (
            <ErrorUrl />
        ) 
    } else if (!isLoaded) {
        return (
            <Cargando />
        ) 
    } else {
        return(
            <div className="container mt-1 mb-5">
                {examenes.map(i => (
                    <Examen key={i.examenId} examen={i} />
                ))}
            </div>
        )
    }
}

export default Examenes

