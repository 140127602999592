import React from 'react'
import { useState, useEffect } from 'react'
import Global from '../Global'

function Costos() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [costos, setCostos] = useState([]);
   
    useEffect(() => {
          fetch(Global.BASE_URL + 'api/webinfo/costos')
              .then(res => res.json())
                  .then(
                      (result) => {
                          setIsLoaded(true);
                          setCostos(result);
                      },
                      (error) => {
                          setIsLoaded(false);
                          setError(error);
                      }
              ) 
    }, [])   

    if (error) {
        return (
            <div className="text-center">No se pudo cargar los costos: {error.message}</div>
        ) 
    } else if (!isLoaded) {
        return (
            <div className="text-center">Cargando los costos... por favor espere...</div>
        ) 
    } else {

        return(
            <div className="py-5 px-2">
                <div className="container bg-white py-4 rounded-lg border shadow">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-md-9">
                            <h2 className="text-center">MODALIDAD DE PAGO</h2>
                            <div className="row" dangerouslySetInnerHTML={{__html: costos.costos}} >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Costos