export default {

    BASE_URL: 'https://cepreuntrmapi.azurewebsites.net/apiweb/',
    
    //BASE_URL: 'https://localhost:44357/',

    getTokenStorage : () => {
        return sessionStorage.getItem('token');
    },
    setTokenStorage : (valor) => {
        sessionStorage.setItem('token', JSON.stringify(valor));
    },
    getEstudianteStorage : () => {
        return sessionStorage.getItem('estudiante');
    },
    setEstudianteStorage : (valor) => {
        sessionStorage.setItem('estudiante', JSON.stringify(valor));
    },
};