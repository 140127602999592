import React from 'react'

function SinPreguntas({volverAGrupos}) {
    return(
        <div className="container anchoContenido d-flex justify-content-center centrado">
        <div className="bg-success text-white rounded p-5 shadow border text-center">
            <h3>Ya no tiene mas preguntas en este grupo</h3>
            <button className="btn btn-info" onClick={volverAGrupos}>Ir a la lista de materias</button>
        </div>
    </div>
    )
}

export default SinPreguntas