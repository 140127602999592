import React from 'react'

function InstrumentoConfirmado({instrumento, handleOnClickAcepto}) {
    return(
        <div className="container mt-5 mb-5">
            <p className="bg-danger text-white p-3 rounded border h5">{instrumento.nombre}</p>
            <div className="ml-3 mt-3">
                <div>
                    <p className="text-info h5 anchoContenido">Objetivo</p>
                    <p>
                        {instrumento.objetivo}
                    </p>
                </div>
                <div className="mt-5">
                    <p className="text-info h5 anchoContenido">Respecto al tiempo</p>
                    <p>
                        {instrumento.detalle}
                    </p>
                </div>
                <div className="mt-5">
                    <p className="text-info h5 anchoContenido">Condiciones</p>
                    <p>El examen está asociado a su inicio de sesion actual. Esto significa que una vez que empieza este examen, usted no debe cerrar la pagina ni tampoco usar el botón actualizar. Si usted actualiza o cierra esta pestaña perderá este examen. Solo use los botones que figuran dentro del cuerpo de nuestra web.</p>
                    
                </div>
            </div>
            <div className="text-center mt-4">
                <button className="btn btn-danger px-4 rounded-pill" onClick={handleOnClickAcepto}>¡Sí, estoy de acuerdo, quiero empezar!</button>
            </div>
        </div>
    )
}

export default InstrumentoConfirmado