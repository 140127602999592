import React from 'react'
import Grupo from './Grupo'

function InstrumentoAplicado({estudianteInstrumento, handleOnClickGrupo}) {
    return (
        <div className="container my-5">
            <p className="bg-danger text-white p-3 rounded border h5">{estudianteInstrumento.nombre}</p>
            <div className="row">
                {estudianteInstrumento.grupos.map(x => (
                    <Grupo key={x.preguntaGrupoId} grupo={x} handleOnClickGrupo={handleOnClickGrupo} />
                ))}
            </div>
        </div>
    )
}

export default InstrumentoAplicado;