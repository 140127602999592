import React from 'react'

function PublicacionVideo({video}) {

    return(
        <div key={video.publicacionVideoId} className="mt-2 mb-5">
            <div className="mb-3">
                <span className="mx-2"><img src="/video-icono.png" width="22px" alt="pdf" /></span>
                {video.titulo}
            </div>
            <div className="embed-responsive embed-responsive-16by9">
                <iframe title={video.titulo} className="embed-responsive-item" src={video.urlVideo} allowFullScreen>
                </iframe>
            </div>
        </div>
    )
}

export default PublicacionVideo

