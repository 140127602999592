import React from 'react'
import Global from '../../Global'

function ExamenArchivo({examen}) {

    let url = Global.BASE_URL + 'api/archivos/general/' + examen.archivo

    return(
        <div key={examen.examenId} className="my-2">
            <span className="ml-2"><img src="/pdf-icono.png" width="22px" alt="pdf" /></span>
            <a href={url}> {examen.nombre} </a>
        </div>
    )
}

export default ExamenArchivo
