import React from 'react'
import { useEffect , useContext,  useState} from 'react'
import Global from '../../Global'
import UsuarioContext from '../../UsuarioContext'
import Pregunta from './Pregunta'
import ErrorUrl from '../ErrorUrl'
import Cargando from '../Cargando'
import SinPreguntas from './SinPreguntas'
import CabeceraPregunta from './CabeceraPregunta'
import MensajeInformacion from './MensajeInformacion'
import notify from 'devextreme/ui/notify';

function PreguntaAplicada({estudianteInstrumento, grupoId, volverAGrupos}) {

    const valorInicialGrupoPreguntas = {
        grupoId: -1,
        nombre: '',
        preguntas: [
            {
                preguntaId: -1,
                numero: -1,
                enunciado: '',
                tieneImagen: false,
                imagen: '',
                respuestas: []
            }
        ]
    }

    const [ estadoUsuario ] = useContext(UsuarioContext);

    const [error, setError] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [grupoPreguntas, setGrupoPreguntas] = useState(valorInicialGrupoPreguntas)
    const [preguntas, setPreguntas] = useState([])

    const [indice, setIndice] = useState(0);
    const [longitud, setLongitud]= useState(1);

    const actualizarData = (result) => {
        setGrupoPreguntas(result);
        setPreguntas(result.preguntas);
        setLongitud(result.preguntas.length)
    }

    useEffect(() => {

        let url = Global.BASE_URL + 'api/evalgrupo';

        let datos = {
            estudianteInstrumentoId: estudianteInstrumento.estudianteInstrumentoId,
            grupoId: grupoId * 1
        };

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${estadoUsuario.token}`);

        var miInit = { 
            method: 'POST', 
            body: JSON.stringify(datos), 
            headers: myHeaders,
        };

        fetch(url, miInit)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    actualizarData(result);
                },
                (error) => {
                    setIsLoaded(false);
                    setError(error);
                    setGrupoPreguntas(valorInicialGrupoPreguntas)
                }
            );

    }, [])

    const eliminarPregunta = () => {

        let nuevaLongitud = preguntas.length - 1;
        if (nuevaLongitud < 0) {
            nuevaLongitud = 0;
        }
        setLongitud(nuevaLongitud)

        setPreguntas(preguntas.filter((e)=>(e.preguntaId !== preguntaActualId)))
        
        if (indice === nuevaLongitud ) {
            setIndice(0);
        }

        setPreguntaActualId(-1);
        setRespuestaActualId(-1);

    }


    const [preguntaActualId, setPreguntaActualId] = useState(-1)
    const [respuestaActualId, setRespuestaActualId] = useState(-1)

    const handledOnClickGuardarRespuesta = (e) => {

        if ((preguntaActualId > 0) && (respuestaActualId > 0)) {


            setIsLoaded(false);

            let url = Global.BASE_URL + 'api/evalpregunta';

            let datos = {
                estudianteInstrumentoId: estudianteInstrumento.estudianteInstrumentoId,
                preguntaId: preguntaActualId * 1,
                respuestaId: respuestaActualId * 1
            };

   
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${estadoUsuario.token}`);
    
            var miInit = { 
                method: 'POST', 
                body: JSON.stringify(datos), 
                headers: myHeaders,
            };
    
            fetch(url, miInit)
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        eliminarPregunta();

                        if (result === true) {
                            //mostrando la notificacion
                            notify({
                                message: "Respuesta guardada",
                                position: {
                                    my: 'center top',
                                    at: 'center top'
                                }
                            }, "success", 2000);
                        } else  {
                            //mostrando la notificacion
                            notify({
                                message: "NO se pudo guardar la respuesta",
                                position: {
                                    my: 'center top',
                                    at: 'center top'
                                }
                            }, "error", 2000);
                        }

                    },
                    (error) => {
                        setIsLoaded(false);
                        setError(error);
                    }
                );   
        }


    }

    const handledOnClickRespuesta = (e) => {

        let n = e.target.name * 1;
        let v = e.target.value * 1

        setPreguntaActualId(n);
        setRespuestaActualId(v);

    }


    const handledOnClickBoton = (e) => {
        let valor = e.target.value * 1;
        let nuevo = 0;
        if (valor === -1) {
            nuevo = indice - 1;
            if (nuevo < 0) {
                nuevo = 0;
            }
        } else if (valor === 1) {
            nuevo = indice + 1;
            if (nuevo === longitud) {
                nuevo = longitud - 1;
            }
        } 

        setIndice(nuevo);

    } 


    if (error) {
        return (
            <ErrorUrl />
        )
    } else if (!isLoaded) {
        return (
            <Cargando />
        )
    } else { 

        if (grupoPreguntas.exito === false) {
            return (
                <MensajeInformacion mensaje={grupoPreguntas.mensaje} urlDestino={"/evaluacion"} />
            )
        } else {
            if (preguntas.length === 0) {
                return (
                    <React.Fragment>
                        <CabeceraPregunta examenNombre={estudianteInstrumento.nombre} grupoNombre={grupoPreguntas.nombre} volverAGrupos={volverAGrupos}/>
                        <SinPreguntas volverAGrupos={volverAGrupos}/>
                    </React.Fragment>
                )
            } else {

                let item = preguntas[indice];

                if (item === undefined) {
                    return(
                        <React.Fragment>
                            <CabeceraPregunta examenNombre={estudianteInstrumento.nombre} grupoNombre={grupoPreguntas.nombre} volverAGrupos={volverAGrupos} />
                            <Cargando />
                        </React.Fragment>
                    )
                } else {
                    return(
                        <div className="container my-5">

                            <CabeceraPregunta examenNombre={estudianteInstrumento.nombre} grupoNombre={grupoPreguntas.nombre} volverAGrupos={volverAGrupos}/>

                            <Pregunta key={item.preguntaId} item={item} handledOnClickRespuesta={handledOnClickRespuesta} />            

                            <div className="mt-4 mb-4 d-flex justify-content-end">
                                <div>
                                    <button value="-1" onClick={handledOnClickBoton} className="btn btn-info px-4">&lt;</button>
                                    <button value="0" onClick={handledOnClickGuardarRespuesta} className="btn btn-success px-4 mx-4">Guardar</button>
                                    <button value="1" onClick={handledOnClickBoton} className="btn btn-info px-4">&gt;</button>
                                </div>
                            </div>

                        </div>
                    )
                }
            }
        }
    }
}

export default PreguntaAplicada