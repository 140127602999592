import React, { useEffect, useContext } from 'react'
import Global from '../Global'
import AulaContext from '../AulaContext'
import UsuarioContext from '../UsuarioContext'
import Curso from './Curso';

function Cursos() {

    const [ estadoUsuario ] = useContext(UsuarioContext);
    const [ aula, setAula ] = useContext(AulaContext);

    useEffect(() => {

        if (aula.cursos.length === 0) {

            let url = Global.BASE_URL + 'api/cursos';

            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${estadoUsuario.token}`);
    
            var miInit = { 
                method: 'GET', 
                headers: myHeaders,
            };
    
            fetch(url, miInit)
            .then(respuesta => {
                if (respuesta.ok) {
                    return respuesta;
                } 
            })
            .then(res => res.json())
            .then(
                (result) => {
                    setAula(
                        {
                            ...aula,
                            cursos: result,
                        }
                    );
                },
                (error) => {
                    setAula(
                        {
                            ...aula,
                            cursos: [],
                        }
                    );
                }
            );

        }

    }, [aula, estadoUsuario, setAula])

    return (

        <div className="container pt-4">
            <h3 className="text-info tamano-fuente">Aula virtual</h3>
            <hr className="border-info"/>
            <div className="row">
                {aula.cursos.map(c => (
                    <Curso key={c.cursoId} curso={c} />
                ) )}
            </div>
        </div>

    )


};

export default Cursos;