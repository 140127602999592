import React from 'react'
import { useState, useContext, useEffect } from 'react'
import UsuarioContext from '../UsuarioContext'
import Global from '../Global'

import Navegar from './Navegar'
import Pie from './Pie'
import Cargando from './Cargando'
import CursoNombre from './CursoNombre'
import Semana from './Semana'
import Temas from './Temas'
import ErrorUrl from './ErrorUrl'

function CursoDetalle({ match }) {

    const [ estadoUsuario ] = useContext(UsuarioContext);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [temas, setTemas] = useState([]);
    

    const [curso] = useState(
        {
            id: match.params.id,
            nombre: match.params.nombre,
        }
    );

    const numeroSemana = estadoUsuario.estudiante.semanaActual;
    const [semanaActual, setSemanaActual] = useState(numeroSemana)

    const handleChange = (e) => {
        setSemanaActual(e.target.value);
    }

    useEffect(() => {

          let url = Global.BASE_URL + "api/temas/" + curso.id;

          var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${estadoUsuario.token}`);

            var miInit = { 
                method: 'GET', 
                headers: myHeaders,
            };

          fetch(url, miInit)
            .then(respuesta => {
                if (respuesta.ok) {
                    return respuesta;
                };
            })
              .then(res => res.json())
                  .then(
                      (result) => {
                          setIsLoaded(true);
                          setTemas(result);
                      },
                      (error) => {
                          setIsLoaded(false);
                          setError(error);
                          setTemas(null);
                      }
              ) 
    }, [curso, estadoUsuario ]);

    if (error) {
        return (
            <ErrorUrl />
        )
    } else if (!isLoaded) {
        return (
            <Cargando />
        )
    } else { 
        return(
            <React.Fragment>
                <Navegar />
                <div className="container mt-4">
                    <CursoNombre nombre={curso.nombre}/>
                    <Semana semanaActual={semanaActual} semanas={estadoUsuario.estudiante.semanas} handleChange={handleChange} />
                    <Temas temas={temas} semanaActual={semanaActual}/>
                </div>
                <Pie />
            </React.Fragment>
        )
    }
}

export default CursoDetalle