import React from 'react'
import PublicacionVideo from './PublicacionVideo'

function PublicacionVideos({videos}) {

    return(
        <div className="font-raleway lead mt-4">
            <h5 className="text-info">Videos explicativos</h5>
            <div className="container">
                {videos.map(v => (
                    <PublicacionVideo key={v.publicacionVideoId} video={v} />
                ))}
            </div>
        </div>
    )
}

export default PublicacionVideos

