import React from 'react'
import { useState, useContext, useEffect } from 'react'
import UsuarioContext from '../UsuarioContext'
import Global from '../Global'

import Navegar from './Navegar'
import Pie from './Pie'
import Cargando from './Cargando'
import BibliotecaNombre from './BibliotecaNombre'
import Publicaciones from './Publicaciones'
import ErrorUrl from './ErrorUrl'

function BibliotecaDetalle({ match }) {

    const [ estadoUsuario ] = useContext(UsuarioContext);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [publicaciones, setPublicaciones] = useState([]);
    

    const [biblioteca] = useState(
        {
            id: match.params.id,
            nombre: match.params.nombre,
        }
    );

    useEffect(() => {

          let url = Global.BASE_URL + "api/publicaciones/" + biblioteca.id;

          var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${estadoUsuario.token}`);

            var miInit = { 
                method: 'GET', 
                headers: myHeaders,
            };

          fetch(url, miInit)
            .then(respuesta => {
                if (respuesta.ok) {
                    return respuesta;
                };
            })
              .then(res => res.json())
                  .then(
                      (result) => {
                          setIsLoaded(true);
                          setPublicaciones(result);
                      },
                      (error) => {
                          setIsLoaded(false);
                          setError(error);
                          setPublicaciones(null);
                      }
              ) 
    }, [biblioteca, estadoUsuario ]);

    if (error) {
        return (
            <ErrorUrl />
        )
    } else if (!isLoaded) {
        return (
            <Cargando />
        )
    } else { 
        return(
            <React.Fragment>
                <Navegar />
                <div className="container mt-4">
                    <BibliotecaNombre nombre={biblioteca.nombre}/>
                    <Publicaciones publicaciones={publicaciones} />
                </div>
                <Pie />
            </React.Fragment>
        )
    }
}

export default BibliotecaDetalle

