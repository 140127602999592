import React from 'react'
import { Link } from 'react-router-dom'

function Biblioteca({biblioteca}){

    const url = `/bibliotecadetalle/${biblioteca.bibliotecaId}/${biblioteca.titulo}`;

    return(
        <Link to={url} className="col-12 col-sm-6 col-md-4 col-lg-3 text-decoration-none">
            <div>
                <div  className="bg-primary text-white text-center w-100 rounded p-5 my-3">
                    {biblioteca.titulo} 
                </div>
            </div>
        </Link>
    )
}

export default Biblioteca
