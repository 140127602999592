import React from 'react'
import { Link } from 'react-router-dom'

function Curso({curso}){

    const url = `/cursodetalle/${curso.cursoId}/${curso.nombre}`;

    return(
        <Link to={url} className="col-12 col-sm-6 col-md-4 col-lg-3 text-decoration-none">
            <div>
                <div  className="bg-success text-white text-center w-100 rounded p-5 my-3">
                    {curso.nombre} 
                </div>
            </div>
        </Link>
    )
}

export default Curso