import React from 'react'
import { useState, useEffect } from 'react'
import NoticiasCore from './NoticiasCore'
import Global from '../../Global'

function Noticias() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

     useEffect(() => {
        fetch(Global.BASE_URL + 'api/noticias')
            .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setItems(result);
                    },
                    (error) => {
                        setIsLoaded(false);
                        setError(error);
                    }
            )
    }, [])

    let contenido = "";

    if (error) {
        contenido = <div className="text-center">No se pudo mostrar las noticias. Error: {error.message}</div>
    } else if (!isLoaded) {
        contenido = <div className="text-center">Intentando mostrar las noticias.. por favor espere...</div>
    } else {
        contenido = <NoticiasCore lista={items} />
    }

    return(
        <div className="py-5 px-2">
            <div className="container bg-white rounded-lg py-4 shadow border">
                <h3 className="text-center">Noticias y avisos</h3>
                {contenido}
            </div>
        </div>
    )

}

export default Noticias