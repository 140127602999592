import React from 'react'
import { useState, useEffect } from 'react'
import Global from '../Global'
import Navegacion from './Navegacion'
import Pie from './Pie'

function EquipoTrabajo() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [equipo, setEquipo] = useState([]);
   
    useEffect(() => {
          fetch(Global.BASE_URL + 'api/webinfo/equipo')
              .then(res => res.json())
                  .then(
                      (result) => {
                          setIsLoaded(true);
                          setEquipo(result);
                      },
                      (error) => {
                          setIsLoaded(false);
                          setError(error);
                      }
              ) 
    }, [])   

    if (error) {
        return (
            <div className="text-center">No se pudo cargar la página: {error.message}</div>
        ) 
    } else if (!isLoaded) {
        return (
            <div className="text-center">Cargando la página... por favor espere...</div>
        ) 
    } else {
        return(
            <div className="w-100 fondo-body">
                <Navegacion />
                <div className="py-5 px-2">
                    <div className="bg-white container py-5 p-md-5 shadow rounded">
                        <h2 className="text-center">EQUIPO DE TRABAJO</h2>
                        <div className="mt-4">
                            <div className="font-raleway" dangerouslySetInnerHTML={{__html: equipo.equipoTrabajo}} >
                            </div>
                        </div>
                    </div>
                </div>
                <Pie />
            </div>
        )
    }
}

export default EquipoTrabajo