import React from 'react';
import { Link } from 'react-router-dom';

function PublicacionNombre({nombre}) {

    return (

        <div className="bg-primary rounded shadow border d-flex justify-content-between align-items-center p-2 p-sm-4">
            <h3 className="mr-auto ml-2 pr-2 text-white tamano-fuente">{nombre}</h3>
            <div className="d-flex align-items-center">
                <Link to="/bibliotecaaula" className="btn btn-black text-white px-4 rounded-pill d-flex align-items-center">Cambiar</Link>
            </div>
        </div>

    )
}

export default PublicacionNombre
